import '../styles/Footer.css';
import '../styles/Global.css';
// Footer

const Footer = () => (
  <div id='footer' className='text-center'>
    <div>
      <div className='montserrat'>Let's sea!</div> <br /> Remember to follow us
      on social media @Otacruise{' '}
    </div>
  </div>
);

export default Footer;
